@import '@/styles/variables';

.button {
  cursor: pointer;
  padding: 10px 0;
  width: 100%;
  min-height: 52px;
  border: none;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  &:focus {
    outline: none;
  }
}

.text {
  font-family: $font-gt-display;
  font-size: 20px;
  line-height: 1.2;
  font-weight: $font-weight-bold;
  padding-left: 15px;
  padding-right: 10px;
}

.icon {
  padding-right: 15px;
}

.dark {
  background-color: $color-navy;

  .text {
    color: $color-white;
  }

  &:hover {
    background-color: $color-navy-lighter;
  }
}

.light {
  background-color: $color-pastel-orange;

  &:hover {
    background-color: $color-pastel-orange-lighter;
  }

  .text {
    color: $color-navy;
  }
}

.transparent {
  background-color: hsla(0, 0%, 100%, 0.1);

  &:hover {
    background-color: hsla(0, 0%, 100%, 0.25);
  }

  .text {
    color: $color-white;
  }
}

.transparent-dark {
  background-color: rgba($color-black, 0.1);

  &:hover {
    background-color: rgba($color-black, 0.1);
  }

  .text {
    color: $color-navy;
  }
}

.green-dark {
  background-color: $color-green-woodland;

  .text {
    color: $color-white;
  }

  &:hover {
    background-color: $color-green-land;
  }
}
