//colors:
$color-navy: #072241;
$color-navy-lighter: #394e67;
$color-black: #000000;
$color-white: #ffffff;
$color-white-opacity-20: rgba(255, 255, 255, 0.2);
$color-white-opacity-40: rgba(255, 255, 255, 0.4);
$color-white-opacity-50: rgba(255, 255, 255, 0.5);
$color-white-opacity-70: rgba(255, 255, 255, 0.7);
$color-transparent: transparent;
$color-blue: #004d69;
$color-grey: #999999;
$color-dark-grey: #777777;
$color-dark2-grey: #333333;
$color-grey2: #e9e9e4;
$color-grey3: #dbdbd6;

$color-green-woodland: #3d5028;
$color-green-land: #3c5c29;

$color-pastel-orange-lighter: #f6e7d5;
$color-pastel-orange: #fbf2e7;
$color-orange-dark: #c96d36;
$color-secondary-lighter: #e5e5e5;
$color-secondary: #d1d1d1;
$color-black-opacity-10: rgba(0, 0, 0, 0.1);
$color-black-opacity-20: rgba(0, 0, 0, 0.2);
$color-black-opacity-50: rgba(0, 0, 0, 0.5);
$color-purple-1: #8f4e6d;
$color-pastel-blue: #edf3f9;
$color-primary-orange: #f5841f;
$color-light-theme: #fbf2e7;
$color-olive: #9a8e3d;
$color-olive-second: #aea463;
$color-yellow: #cb9e27;
$color-red-1: #b4284c;

$color-pink: #f5ddd7;
$color-pastel-pink: #ffecf1;
//fonts

$font-gt-display: 'GT Eesti Pro Display';
$font-cambon: 'Cambon';

$font-weight-thin: 100;
$font-weight-ultra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;
$font-style-italic: italic;
$font-style-normal: normal;

// Opacity
$opacity-7: 0.7;
$opacity-50: 0.5;
$opacity-25: 0.2;
$opacity-10: 0.1;

//Media Screen
$xs: 360px;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1366px;
$xxxl: 1600px;
$fhd: 1921px;
