@import '@/styles/_variables';

.container {
  width: 100%;
  min-height: 160px;
  background-color: #edf3f9;
  @media (min-width: $lg) {
    padding-bottom: 50px;
  }
}
.header {
  margin: 0 auto;
  width: 83%;
  padding-top: 44px;
  padding-bottom: 19px;
  @media (min-width: $lg) {
    padding-top: 65px;
    padding-bottom: 50px;
    padding-left: 39px;
    width: 94%;
    margin: 0 auto;
  }
  @media (min-width: $xxxl) {
    width: 100%;
    padding-left: 178px;
  }
  @media (min-width: $fhd) {
    padding-left: 15vw;
  }
}

.title {
  color: $color-navy;
  font-family: $font-cambon;
  font-size: 36px;
  font-weight: $font-weight-bold;
  line-height: 40px;
  @media (min-width: $lg) {
    font-size: 42px;
    line-height: 44px;
    padding-left: 35px;
  }
}

.cards {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: $lg) {
    width: 94%;
    margin: 0 auto;
    padding-bottom: 50px;
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: $xxxl) {
    width: 100%;
    padding-left: 178px;
    padding-right: 178px;
  }
  @media (min-width: $fhd) {
    padding-left: 15vw;
    padding-right: 15vw;
  }

  .card {
    margin: 0 auto;
    width: 100%;
    padding: 0 25px;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: 180px;
    column-gap: 20px;
    &:nth-of-type(odd) {
      background-color: #dee5ef;
    }
    &:nth-of-type(even) {
      background-color: #e1ebf2;
    }

    @media (min-width: $lg) {
      min-height: 340px;
      padding: 0;
      padding-left: 30px;
      padding-right: 20px;
      padding-top: 40px;
      grid-template-columns: 1fr;
      grid-template-rows: min-content 1fr min-content;
      column-gap: 20px;
    }

    .iconArrow {
      width: 21px;
      height: 31px;
      cursor: pointer;
    }

    .buttonWrapper {
      display: block;
      align-self: center;
      @media (min-width: $lg) {
        justify-self: end;
        margin-bottom: 30px;
      }
    }

    .iconMain {
      padding-top: 30px;
      @media (min-width: $lg) {
        padding: 0;
        width: 55px;
        height: 55px;
      }
    }

    .text {
      padding-top: 30px;
      @media (min-width: $lg) {
        padding-top: 20px;
        padding-bottom: 40px;
      }
      h4 {
        padding-bottom: 20px;
        color: $color-navy;
        font-family: $font-cambon;
        font-size: 24px;
        font-weight: $font-weight-bold;
        line-height: 26px;
        @media (min-width: $lg) {
          font-size: 22px;
          line-height: 24px;
        }
      }
      p {
        color: $color-navy;
        font-family: $font-gt-display;
        font-size: 16px;
        font-weight: $font-weight-medium;
        line-height: 20px;
      }
    }
  }
}
