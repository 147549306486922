@import '@/styles/_variables';

.containerModal {
  position: fixed;
  z-index: 9000;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100vh;
  background-color: rgb(27, 25, 25);
  overscroll-behavior: contain;
}

.container {
  height: 90%;
  overflow: auto;
  display: grid;

  @media (min-width: $md) {
    height: 100%;
  }
}

.content {
  align-self: center;
  padding: 0 5vw;
  height: 100%;
  @media (min-width: $xxxl) {
    padding: 0 10vw;
  }
}

.box {
  position: relative;
  padding-bottom: 56.25%;
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  z-index: 9001;
  top: 22px;
  right: 18px;
}
@media (min-width: $md) {
  .icon {
    top: 60px;
    right: 20px;
    width: 28px;
    height: 28px;
  }
}

.header {
  padding-bottom: 18px;
  border-bottom: 1px solid $color-white;
  padding-top: 30px;
  display: inline-block;
  padding-right: 14px;
  padding-left: 14px;
  margin-left: 5vw;
  margin-right: 5vw;
  width: calc(100% - 10vw);

  @media (min-width: $md) {
    width: fit-content;
    padding-top: 100px;
    padding-right: 36px;
    margin-bottom: 68px;
  }

  @media (min-width: $xxxl) {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

.title {
  font-family: $font-cambon;
  font-weight: $font-weight-extra-bold;
  font-size: 24px;
  line-height: 1.1;
  color: $color-white;
  margin-bottom: 5px;

  @media (min-width: $lg) {
    font-size: 32px;
  }
}

.subtitle {
  font-family: $font-cambon;
  font-weight: $font-weight-extra-bold;
  font-size: 16px;
  line-height: 1.1;
  color: $color-white;

  @media (min-width: $lg) {
    font-size: 18px;
  }
}

.buttonWrap {
  max-width: 356px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0;
  align-self: end;
  margin-bottom: 20px;
  margin-top: 20px;
  @media (min-width: $md) {
    padding-bottom: 60px;
    align-self: start;
  }
}

.withHeader {
  .container {
    display: grid;
    grid-template-rows: 1fr min-content min-content;
    padding-top: 60px;

    @media (min-width: $md) {
      padding-top: 0;
      grid-template-rows: 1fr max-content 1fr;
    }
  }

  .content {
    grid-row: 1/2;

    @media (min-width: $md) {
      grid-row: 2/3;
      margin-bottom: 52px;
    }
  }
}
