@import '../../../styles/_variables';

.large {
  width: 16px;
  height: 26px;
}
.small {
  width: 10px;
  height: 16px;
}
.inherit {
  width: inherit;
  height: inherit;
}

@media (min-width: $lg) {
  .large {
    width: 20px;
    height: 32px;
  }
  .small {
    width: 10px;
    height: 16px;
  }
}
